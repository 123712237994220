<template>
  <div
    v-if="expand"
    :id="
      typeData === 'sankey' ? 'reportOverview' : 'workflow_overview_dashboard'
    "
  >
    <div
      style="
        cursor: pointer;
        border-bottom: 1px solid var(--divider-color);
        margin-bottom: 5px;
        margin-top: 10px;
      "
      class="row q-pb-sm"
      @click.stop="expand = false"
    >
      <span class="col-auto"
        ><BaseIcon
          v-tooltip.top="findIcon.iconTootip"
          :name="findIcon.icon"
          :color="findIcon.color"
          class="q-mr-sm q-pb-sm"
          size="25px" /></span
      ><span
        class="col text-base font-bold text-uppercase items-center text-black ellipsis"
        style="cursor: pointer; border-right: 0px"
        >{{ findValuename }}
        <div
          class="q-mt-xs text-base font-bold text-uppercase items-center ellipsis"
          style="
            color: #475569;
            width: 100%;
            cursor: pointer;
            border-right: 0px;
          "
        >
          {{ findValue.description }}
        </div></span
      >
      <div v-if="filterContains.length" class="icon-container">
        <BaseActionButton
          is-flat
          no-border
          icon="eva-eye"
          color="primary"
          class="q-ml-sm col-auto icon"
        />
        <div class="iconcontent" @click.stop="">
          <div
            v-for="(item, index) in filterContains"
            :key="'model' + index"
            class="col-12 q-mr-sm row items-center filerItem"
            style="width: 100%"
          >
            <span
              class="q-ml-sm q-mr-xs q-mt-sm q-mb-sm text-base font-bold text-black items-center"
              style="cursor: pointer; border-right: 0px"
            >
              {{ item.label }}</span
            >
          </div>
        </div>
      </div>
      <BaseActionButton
        v-tooltip.top="'refresh'"
        is-flat
        no-border
        icon="eva-refresh"
        class="q-ml-sm col-auto"
        @click.stop="refresh"
      />

      <BaseActionButton
        v-tooltip.top="'close'"
        is-flat
        no-border
        icon="eva-arrow-ios-upward-outline"
        class="q-ml-sm col-auto"
        @click.stop="findItem"
      />
      <BaseActionButton
        v-tooltip.bottom="'UnPin'"
        icon="mdi-pin"
        color="secondary"
        no-border
        is-flat
        size="20px col-auto"
        rotate="rotate-45"
        @click.stop="deleteItem"
      />
    </div>
    <div v-if="loadingBarContent">
      <ProgressBar
        :container-height="'75vh'"
        :container-color="'white'"
        :progress-bars="progressBars"
      />
    </div>
    <div v-else class="chart chartBox">
      <div class="row text-capitalize items-center header">
        <div class="col-8">
          {{ filterItem.workflowStatus }}
        </div>
        <div class="col-4">
          <!-- <BaseActionButton
            v-if="item.dashboard"
            icon="mdi-pin"
            color="secondary"
            no-border
            is-flat
            size="20px"
            rotate="rotate-45"
            @click="$emit('isDelete')"
          />
          <BaseActionButton
            v-else
            icon="mdi-pin"
            :color="'gray'"
            no-border
            is-flat
            size="20px"
            rotate="rotate-45"
            @click="saveFilter(item, 'report')"
          /> -->
        </div>
      </div>
      <div v-if="filterListData.type === 'report'" class="column">
        <template>
          <div class="text-primary col self-center q-mt-lg q-mb-sm count">
            {{ findValue.description }}
          </div>
        </template>
        <template>
          <div class="text-primary col self-center q-mt-lg q-mb-sm count">
            {{ count }}
          </div>
        </template>
      </div>
      <div v-if="filterListData.type === 'Pie'">
        <div
          v-for="(item, key) in pieChartDatas"
          :key="'dropdown-pie' + key"
          class="col-4 q-pr-sm q-pb-sm"
          style="height: 350px"
        >
          <PieChart
            :title="item.name"
            :x-data="item.xAxis"
            :y-data="item.yAxis"
            :options="pieChartOptions"
            :dashboard="item.dashboard"
            :dashboard-view="true"
            @pin="saveFilter(item, 'Pie')"
          />
        </div>
      </div>
      <div v-if="filterListData.type === 'bar'">
        <div
          v-for="(item, index) in barChartDatas"
          :key="'overall-bar' + index"
          class="col-4 q-pr-sm q-pb-sm"
          style="height: 350px"
        >
          <BarChart
            :title="item.key"
            :x-data="item.xAxis"
            :y-data="item.yAxis"
            :is-horizontal="true"
            :is-stacked="true"
            :options="barChartOptions"
            :dashboard="item.dashboard"
            :dashboard-view="true"
            @pin="saveFilter(item, 'bar')"
          />
        </div>
      </div>
      <template v-if="typeData === 'sankey'">
        <div class="workflowCharts q-mb-sm">
          <div class="row border-bottom">
            <div class="text-bold q-pa-sm col-auto">Data Link Chart</div>
            <template v-if="workflowId">
              <div class="row col">
                <BaseIcon
                  v-tooltip.top="'filter'"
                  name="eva-funnel-outline"
                  color="secondary"
                  class="q-mr-sm q-mt-sm"
                  size="24px"
                />
                <template v-for="(field, index) in sankeyChartsFilter">
                  <div :key="field.id" class="col-auto">
                    <div class="row">
                      <div class="col-auto">
                        <SelectField
                          v-model="sankeyChartsFilter[index]"
                          :options="formFields"
                          :is-clearable="false"
                          class="fieldList q-mb-sm"
                          @input="loadingOverviewChart"
                        >
                        </SelectField>
                      </div>
                      <div class="col-auto">
                        <BaseActionButton
                          v-tooltip.top="'remove filter'"
                          icon="mdi-close"
                          class="plus q-mx-sm"
                          size="14px"
                          is-flat
                          color="red"
                          @click="removeSankeyChartsFilter(index)"
                        />
                      </div>
                    </div>
                  </div>
                </template>

                <BaseActionButton
                  v-tooltip.top="'add filter'"
                  icon="mdi-plus"
                  color="secondary"
                  class=""
                  @click="addSankeyChartsFilter"
                />
              </div>
              <div class="col-auto">
                <!-- <BaseActionButton
                          v-if="item.dashboard"
                          icon="mdi-pin"
                          color="secondary"
                          no-border
                          is-flat
                          size="20px"
                          rotate="rotate-45"
                          @click="deleteItem(item, 'report')"
                        /> -->
              </div>
              <div class="row col-auto">
                <BaseSeparator is-vertical has-inset class="q-mx-sm" />

                <BaseActionButton
                  v-for="view in dataViews"
                  :key="view.id"
                  v-tooltip.top="view.label"
                  is-flat
                  no-border
                  :icon="dataView === view.value ? view.activeIcon : view.icon"
                  :color="dataView === view.value ? 'secondary' : 'gray'"
                  @click="dataView = view.value"
                />
              </div>
            </template>
          </div>
          <div v-if="dataView === 'SANKEY'" class="row chart">
            <vue-highcharts
              ref="refCharts"
              :highcharts="Highcharts"
              :options="sankeyCharts"
              style="width: 100%"
            ></vue-highcharts>
          </div>
          <div v-else-if="dataView === 'TABLE'">
            <div class="workflowReports">
              <BaseScrollbar height="300px">
                <table>
                  <thead>
                    <tr>
                      <!-- columns -->

                      <th v-for="column in columns" :key="column.id">
                        {{ column.name }}
                      </th>

                      <!-- ... -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in tableReport" :key="item.id">
                      <!-- columns -->

                      <td v-for="column in columns" :key="column.id">
                        <slot
                          :name="column.name"
                          :value="item[column.name]"
                          :item="item"
                        >
                          {{ item[column.name] }}
                        </slot>
                      </td>

                      <!-- ... -->
                    </tr>
                  </tbody>
                </table>
              </BaseScrollbar>
            </div>
          </div>
        </div>
      </template>
      <div v-if="filterListData.type === 'fullchart'">
        <div class="col-4 q-pr-sm q-pb-sm" style="height: 350px">
          <div class="col-12 q-pr-sm q-mb-sm">
            <div class="chart">
              <div class="row item-center q-body q-mb-sm">
                <div
                  class="col-auto q-mr-xs row cursor-pointer header"
                  :class="chartDataDrilldownStage.month ? 'text-secondary' : ''"
                  style="width: 100%"
                  @click="
                    chartDataDrilldownStage.month = '';
                    chartDataDrilldownStage.week = '';
                    getWorkflowChartCount();
                  "
                >
                  Month
                </div>
                <div
                  v-if="chartDataDrilldownStage.month"
                  class="col-auto cursor-pointer header"
                  :class="chartDataDrilldownStage.week ? 'text-secondary' : ''"
                  @click="
                    chartDataDrilldownStage.week = '';
                    getWorkflowChartCount(chartDataDrilldownStage.month);
                  "
                >
                  / {{ chartDataDrilldownStage.month }}
                </div>
                <div
                  v-if="chartDataDrilldownStage.week"
                  class="col-auto q-ml-xs header"
                  @click="chartDataDrilldownStage.week = ''"
                >
                  / {{ chartDataDrilldownStage.week }}
                </div>
              </div>
              <vue-highcharts
                ref="refWorkflowChart"
                :highcharts="Highcharts"
                :options="chartData"
              ></vue-highcharts>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ConfirmDelete
      v-model="isDeleteConfirmationVisible"
      :delete-data="deleteData"
      :label="findValuename"
      @delete="$emit('delete')"
    />
  </div>

  <div v-else class="col-12">
    <div
      :id="
        typeData === 'sankey' ? 'reportOverview' : 'workflow_overview_dashboard'
      "
      style="cursor: pointer"
      class="text-base font-bold text-uppercase items-center text-black row"
      @click="findItem"
    >
      <span class="col-auto"
        ><BaseIcon
          v-tooltip.top="findIcon.iconTootip"
          :name="findIcon.icon"
          :color="findIcon.color"
          class="q-mr-sm q-pb-sm"
          size="25px" /></span
      ><span
        class="col text-base font-bold text-uppercase items-center text-black ellipsis"
        style="cursor: pointer; border-right: 0px"
        >{{ findValuename }}
        <div
          class="q-mt-xs text-base font-bold text-uppercase items-center ellipsis"
          style="
            color: #475569;
            width: 100%;
            cursor: pointer;
            border-right: 0px;
          "
        >
          {{ findValue.description }}
        </div></span
      >
      <div v-if="filterContains.length" class="icon-container">
        <BaseActionButton
          is-flat
          no-border
          icon="eva-eye"
          color="primary"
          class="q-ml-sm col-auto icon"
        />
        <div class="iconcontent" @click.stop="">
          <div
            v-for="(item, index) in filterContains"
            :key="'model' + index"
            class="col-12 q-mr-sm row items-center filerItem"
            style="width: 100%"
          >
            <span
              class="q-ml-sm q-mr-xs q-mt-sm q-mb-sm text-base font-bold text-black items-center"
              style="cursor: pointer; border-right: 0px"
            >
              {{ item.label }}</span
            >
          </div>
        </div>
      </div>
      <BaseActionButton
        v-tooltip.top="'refresh'"
        is-flat
        no-border
        icon="eva-refresh"
        class="q-ml-sm col-auto"
        @click.stop="refresh"
      />

      <BaseActionButton
        v-tooltip.top="'open'"
        is-flat
        no-border
        icon="eva-arrow-ios-downward-outline"
        class="q-ml-sm col-auto"
        @click.stop="findItem"
      />
      <BaseActionButton
        v-tooltip.bottom="'UnPin'"
        icon="mdi-pin"
        color="secondary"
        no-border
        is-flat
        size="20px"
        rotate="rotate-45"
        class="col-auto"
        @click.stop="deleteItem"
      />
      <ConfirmDelete
        v-model="isDeleteConfirmationVisible"
        :label="findValuename"
        :delete-data="deleteData"
        @delete="$emit('delete')"
      />
    </div>
  </div>
</template>

<script>
import { overview, report, workflow } from "@/api/factory.js";
import BarChart from "@/components/common/charts/BarChart.vue";
import PieChart from "@/components/common/charts/PieChart.vue";
import Exporting from "highcharts/modules/exporting.js";
import Drilldown from "highcharts/modules/drilldown.js";
import Highcharts from "highcharts";
import VueHighcharts from "vue2-highcharts";
import ConfirmDelete from "@/components/common/display/item-actions/components/UnpinDashboard.vue";
import ProgressBar from "@/views/dashboard/components/MobileLoadingBar.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import { SankeyController, Flow } from "chartjs-chart-sankey";
import { Chart, LinearScale } from "chart.js";
import ExportinSankey from "../../../../node_modules/highcharts/modules/sankey.js";

Drilldown(Highcharts);
Exporting(Highcharts);
ExportinSankey(Highcharts);

Chart.register(LinearScale, SankeyController, Flow);

export default {
  name: "DashboardWorkflowOverview",

  components: {
    BarChart,
    PieChart,
    VueHighcharts,
    ConfirmDelete,
    ProgressBar,
    SelectField,
  },

  props: {
    filterItem: {
      type: Object,
      required: true,
    },
    listedData: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      expand: false,
      workflowId: 0,
      count: 0,
      pieChartData: [],
      pieChartDatas: [],
      pieChartOptions: {
        chart: {
          height: "85%",
        },
      },
      barChartData: [],
      barChartDatas: [],
      barChartOptions: {
        chart: {
          height: "85%",
        },
      },
      Highcharts: Highcharts,
      chartData: {
        chart: {
          type: "spline",
          height: "330px",
        },
        title: {
          text: "",
        },
        subtitle: {
          text: "",
        },
        xAxis: {
          // categories: [],
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        yAxis: {
          title: {
            text: "Count",
          },
          labels: {
            formatter: function () {
              return this.value;
            },
          },
        },
        tooltip: {
          crosshairs: true,
          shared: true,
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        colors: ["#bfa0fe"],
        plotOptions: {
          series: {
            cursor: "pointer",
            point: {
              events: {
                click: (e) => {
                  e.stopPropagation();
                  if (e.point.name && e.point.y) {
                    if (this.chartDataDrilldownStage.month === "") {
                      this.chartDataDrilldownStage.month = e.point.name;
                      this.getWorkflowChartCount(e.point.name);
                    } else if (this.chartDataDrilldownStage.week === "") {
                      this.chartDataDrilldownStage.week = e.point.name;
                      this.getWorkflowChartCount(
                        this.chartDataDrilldownStage.month,
                        e.point.name.replace("Week ", "")
                      );
                    }
                  }
                },
              },
            },
          },
          spline: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [
          // {
          //   name: "Running",
          //   data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0],
          // },
          // {
          //   name: "Completed",
          //   data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 21, 11],
          // },
        ],
        drilldown: {},
      },
      chartDataDrilldownStage: { month: "", week: "" },
      findIcon: {},
      deleteData: {},
      isDeleteConfirmationVisible: false,
      success: false,
      loadingBarContent: false,
      progressBars: [{ width: "30%" }, { width: "50%" }, { width: "70%" }],
      sankeyChartsFilter: [],
      sankeyCharts: {
        title: {
          text: "",
        },
        credits: {
          enabled: false,
        },
        accessibility: {
          point: {
            valueDescriptionFormat:
              "{index}. {point.from} to {point.to}, {point.weight}.",
          },
        },
        series: [
          {
            keys: ["from", "to", "weight"],
            data: [],
            type: "sankey",
          },
        ],
      },
      tableReport: [],
      tableReportData: [],
      dataViews: [
        {
          id: this.$nano.id(),
          label: "Sankey Diagram",
          value: "SANKEY",
          icon: "mdi-chart-sankey-variant",
          activeIcon: "mdi-chart-sankey-variant",
        },
        {
          id: this.$nano.id(),
          label: "Table View",
          value: "TABLE",
          icon: "mdi-table",
          activeIcon: "mdi-table",
        },
      ],
      dataView: "SANKEY",
      kanbanSettings: [],
      kanbanSettingsList: [],
      isVisibleKanbanView: false,
      showKanbanView: false,
      workflowProcessList: [],
      typeData: "",
      columns: [],
      findValuename: "",
      filterContains: [],
    };
  },

  computed: {},

  watch: {
    item: {
      immediate: true,
      deep: true,
      handler() {
        if (this.filterItem) {
          this.findValue = this.filterItem;
          this.expand = this.findValue.expand;
          if (this.findValue.label) {
            this.findValuename = this.removeTimestampFromString(
              this.findValue.label
            );
          }
          let parse = JSON.parse(this.findValue.filterJson);
          let findIcons = parse[0].type;
          this.typeData = findIcons;

          console.log(parse, "parse");
          if (findIcons === "report") {
            this.findIcon = {
              icon: "mdi-finance",
              iconTootip: "Workflow Request",
              color: "orange",
            };
          } else if (findIcons === "Pie") {
            this.findIcon = {
              icon: "mdi-chart-pie",
              iconTootip: "Workflow Pie Chart",
              color: "purple",
            };
          } else if (findIcons === "fullchart") {
            this.findIcon = {
              icon: "mdi-chart-line",
              iconTootip: "Workflow Line Chart",
              color: "yellow",
            };
            this.iconTootip = "Payment";
          } else if (findIcons === "bar") {
            this.findIcon = {
              icon: "mdi-chart-bar",
              iconTootip: "Workflow Bar Chart",
              color: "green",
            };
            this.iconTootip = "Completed";
          } else if (findIcons === "sankey") {
            this.findIcon = {
              icon: "mdi-chart-sankey-variant",
              iconTootip: "Workflow sankey Chart",
              color: "purple",
            };
            this.iconTootip = "Completed";
          }
        }
        let filter = JSON.parse(this.filterItem.filter);
        console.log(filter, "filterds");
        let filters = filter[0].label;

        console.log(filters, "filterdss");
        if (filters.length) {
          const transformedData = filters;
          this.filterContains = transformedData;
        }
        console.log;
      },
    },
    typeData: {
      immediate: true,
      deep: true,
      handler() {
        if (this.typeData === "sankey" && this.workflowId !== 0) {
          if (this.dataView === "SANKEY") {
            this.overviewChart();
          } else if (this.dataView === "TABLE") {
            this.overviewTableReport();
          }
        }
      },
    },

    dataView: {
      immediate: true,
      deep: true,
      handler() {
        if (this.typeData === "sankey" && this.workflowId !== 0) {
          if (this.dataView === "SANKEY") {
            this.overviewChart();
          } else if (this.dataView === "TABLE") {
            this.overviewTableReport();
          }
        }
      },
    },

    // workspaceId: {
    //   deep: true,
    //   immediate: true,
    //   handler() {
    //     if (this.workspaceId) {
    //       this.getRepositories();
    //     }
    //   },
    // },

    // filterId: {
    //   deep: true,
    //   immediate: true,
    //   async handler() {
    //     if (this.filterId === "-1") {
    //       this.showFilterList = true;
    //       this.getFilterData();
    //     } else if (this.filterId) {
    //       this.showFilterList = false;
    //       this.filterList();
    //       this.selectedIds = this.filterId;
    //     } else if (!this.filterId) {
    //       this.showFilterList = false;
    //       this.repositoryReportList = [];
    //     }
    //   },
    // },
  },

  created() {},

  methods: {
    deleteItem() {
      this.listedData.map((item) => {
        if (item.name === this.findValue.label) {
          console.log(item, "itemd");

          this.isDeleteConfirmationVisible = true;
          this.deleteData = {
            id: item.id,
            name: item.name,
            description: item.description,
            filterJson: item.filterJson,
            filterFrom: item.filterFrom,
            createdAt: item.createdAt,
            modifiedAt: item.modifiedAt,
            createdBy: item.createdBy,
            modifiedBy: item.modifiedBy,
            isDeleted: true,
          };
        }
      });
    },
    removeTimestampFromString(str) {
      return str
        .replace(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/, "")
        .trim();
    },
    refresh() {
      if (this.workflowId === 0) {
        this.getUserOverallCount();
      }
      if (this.workflowId !== 0 && this.filterListData.type === "report") {
        this.getWorkflowCount();
      }
      if (
        (this.workflowId !== 0 && this.filterListData.type === "Pie") ||
        (this.workflowId !== 0 && this.filterListData.type === "bar")
      ) {
        this.filterListData.formFields;
        this.getChartByColumns();
      }

      if (this.workflowId !== 0 && this.filterListData.type === "fullchart") {
        this.getWorkflowChartCount();
      }
      if (this.workflowId !== 0 && this.filterListData.type === "sankey") {
        if (this.dataView === "TABLE") {
          this.overviewTableReport();
        } else {
          this.overviewChart();
        }
      }
    },
    handleOpen() {
      this.findItem();
      this.expand = true;
    },
    async findItem() {
      this.expand = true;
      console.log(this.listedData, "this.listedData");
      this.listedData.map((item) => {
        if (item.name === this.findValue.label) {
          this.expand = true;
          const parsedArray = JSON.parse(item.filterJson);
          const filters = JSON.parse(parsedArray[0].filters);
          console.log(parsedArray, "parsedArray");
          this.filterListData = {
            name: item.name,
            description: item.description,
            key: parsedArray[0].key,
            type: parsedArray[0].type,
            rId: parsedArray[0].uId,
            chartFilter: parsedArray[0].chartFilter,
            formFields: parsedArray[0].formField,
            filterFrom: item.filterFrom,
            filters: filters,
          };
        }
      });
      this.filterFrom = this.filterListData.filterFrom;
      this.filterFrom = this.filterListData.filterFrom;
      this.workflowId = Number(this.filterListData.rId);
      this.itemId = 0;
      this.filterBy = this.filterListData.filters;
      if (this.workflowId === 0) {
        this.getUserOverallCount();
      }
      if (this.workflowId !== 0 && this.filterListData.type === "report") {
        this.getWorkflowCount();
      }
      if (
        (this.workflowId !== 0 && this.filterListData.type === "Pie") ||
        (this.workflowId !== 0 && this.filterListData.type === "bar")
      ) {
        this.filterListData.formFields;
        this.getChartByColumns();
      }

      if (this.workflowId !== 0 && this.filterListData.type === "fullchart") {
        this.getWorkflowChartCount();
      }

      if (this.workflowId !== 0 && this.filterListData.type === "sankey") {
        this.formFields = this.filterListData.formFields;
        this.sankeyChartsFilter = this.filterListData.filters;
        await this.overviewChart();
        await this.overviewTableReport();
      }
    },

    async getWorkflowData() {
      this.loadingBarContent = true;
      // this.$store.commit("showLoadingBar");
      this.workflowProcessList = [];

      // this.list = [];
      const { error, payload } = await report.getWorkflowData(this.workflowId, {
        filterBy: [],
        itemsPerPage: 100,
        currentPage: 1,
        mode: "",
      });

      this.loadingBarContent = false;
      // this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      payload.data.forEach((row) => {
        row.value.forEach((process) => {
          this.workflowProcessList.push({
            ...process,
          });
        });
      });

      this.totalItems = payload.meta?.totalItems || 0;
    },

    async getKanbanViewSettings() {
      this.loadingBarContent = true;
      // this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.getKanbanViewSettings(
        this.workflowId
      );
      this.loadingBarContent = false;
      // this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error(error);
        return;
      }
      this.kanbanSettings = [];
      this.kanbanSettingsList = [];
      if (payload.length) {
        this.isVisibleKanbanView = true;
        payload.forEach((item) => {
          this.kanbanSettings.push({
            ...item,
            search: "",
          });
          this.kanbanSettingsList.push({
            ...item,
            search: "",
          });
        });
        // this.kanbanSettingsList = this.kanbanSettings;
      } else {
        this.isVisibleKanbanView = false;
      }
    },

    async getUserOverallCount() {
      this.workflowId = 0;

      this.loadingBarContent = true;
      const { error, payload } = await overview.getUserOverallCount();
      this.loadingBarContent = false;

      if (error) {
        this.$alert.error(error);
        return;
      }
      const key = this.filterListData.key;
      const inboxCount = payload[key];
      this.count = inboxCount;
    },

    async getWorkflowCount() {
      this.loadingBarContent = true;

      const { error, payload } = await overview.getRequestCountByUserId(
        this.workflowId
      );
      this.loadingBarContent = false;

      if (error) {
        this.$alert.error(error);
        return;
      }

      const key = this.filterListData.key;
      const inboxCount = payload[key];
      this.count = inboxCount;
    },

    async getChartByColumns() {
      this.barChartData = [];
      this.pieChartData = [];
      const { error, payload } = await report.getChartByColumnsByUserId(
        this.workflowId,
        this.filterListData.chartFilter
      );

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload) {
        const payloadWithLabels = Object.keys(payload).reduce((acc, key) => {
          const label = this.filterListData.formFields[key.replace(/"/g, "")];
          if (label) {
            acc[label] = payload[key];
          }
          return acc;
        }, {});
        Object.keys(payload).forEach((category) => {
          const categoryName = category.replace(/"/g, "");

          payload[category].forEach((entry) => {
            entry.name = entry[categoryName] ? entry[categoryName] : "";
            delete entry[categoryName];
          });
        });
        if (this.filterListData.type === "bar") {
          Object.keys(payloadWithLabels).forEach((key) => {
            let xAxis = [],
              yAxisSeries1 = [],
              yAxisSeries2 = [];
            payloadWithLabels[key].forEach((row1) => {
              xAxis.push(row1.name);
              yAxisSeries1.push(row1.running);
              yAxisSeries2.push(row1.completed);
            });

            this.barChartData.push({
              key: `${key} - Report Chart`,
              xAxis: xAxis,
              yAxis: [
                {
                  name: "Running",
                  data: yAxisSeries1,
                },
                {
                  name: "Completed",
                  data: yAxisSeries2,
                },
              ],
            });
          });
          console.log(this.barChartData, "chert");
          const str = this.findValue.description;
          console.log(str, "str");
          const foundObject = this.barChartData.find(
            (item) => item.key === str
          );
          this.barChartDatas = [foundObject];
        }
        if (this.filterListData.type === "Pie") {
          Object.keys(payloadWithLabels).forEach((key) => {
            payloadWithLabels[key].forEach((row) => {
              this.pieChartData.push({
                name: `${row.name} - Report Chart`,
                xAxis: ["Running", "Completed"],
                yAxis: [row.running, row.completed],
              });
            });
          });
        }
        console.log(this.pieChartData, "chart");
        const str = this.findValue.description;
        const foundObject = this.pieChartData.find((item) => item.name === str);
        this.pieChartDatas = [foundObject];
        console.log(this.pieChartDatas.xAxis, "chart");
      }
    },
    async getWorkflowChartCount(month = "", weekno = "") {
      this.pinedchart = false;
      this.loadingBarContent = true;
      this.chartData.series = [];
      const weekNumber = weekno.replace(/\D/g, "");
      let input = {
        year: new Date().getFullYear(),
        month: month,
        weekno: weekNumber,
      };
      const { error, payload } = await report.getWorkflowChartByUserId(
        this.workflowId,
        input
      );

      this.loadingBarContent = false;

      if (error) {
        this.$alert.error(error);
        return;
      }

      console.log(payload, "payloading");

      if (payload) {
        if (payload.completed && payload.initiated) {
          let yAxis = [];
          // this.chartData.xAxis.categories = Object.keys(payload.completed);

          yAxis.push({
            name: "Running",
            data: Object.values(payload.initiated).map(Number),
          });

          yAxis.push({
            name: "Completed",
            data: Object.values(payload.completed).map(Number),
          });

          this.chartData.series = yAxis;

          let lineCharts = this.$refs.refWorkflowChart;
          try {
            lineCharts.removeSeries();
          } catch (e) {
            //
          }

          lineCharts.addSeries(this.chartData.series[0]);
          lineCharts.addSeries(this.chartData.series[1]);
        }
      }

      let List = this.listedData.map((filter) => ({
        id: this.$nano.id(),
        label: filter.description,
        filterJson: JSON.parse(filter.filterJson),
        dashboard: false,
      }));
      List.forEach((item) => {
        let id = item.filterJson[0].uId;
        let type = item.filterJson[0].type;
        if (id === this.workflowId && type === "fullchart") {
          this.pinedchart = true;
        }
      });
    },
    loadingOverviewChart() {
      this.overviewChart();
      this.overviewTableReport();
    },
    async overviewChart() {
      this.loadingBarContent = true;
      // this.$store.commit("showLoadingBar");
      try {
        this.$refs.refCharts.removeSeries();
      } catch (e) {
        //
      }

      console.log(this.sankeyChartsFilter, this.formFields);
      const { error, payload } = await workflow.overviewChart(
        this.workflowId,
        this.sankeyChartsFilter
      );

      this.loadingBarContent = false;
      // this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      console.log(payload, "loading");
      if (payload.length) {
        this.sankeyCharts.series[0].data = payload;
        this.sankeyCharts.series[0].name = this.filterListData.description;
        console.log(this.sankeyCharts.series, "this.sankeyCharts.series");
        let sankeyCharts = this.$refs.refCharts;
        try {
          sankeyCharts.removeSeries();
        } catch (e) {
          //
        }

        sankeyCharts.addSeries(this.chartData.series[0]);
        // sankeyCharts.addSeries(this.chartData.series[1]);
      }
    },
    async overviewTableReport() {
      this.columns = [];
      this.loadingBarContent = true;
      // this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.overviewTableReport(
        this.workflowId,
        this.sankeyChartsFilter
      );
      this.loadingBarContent = false;
      // this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        if (payload.Table.length) {
          this.tableReport = payload.Table;
          this.tableReportData = this.tableReport;
          let keys = Object.keys(this.tableReport[0]);
          keys.forEach((key) => {
            this.columns.push({
              id: this.$nano.id(),
              name: key,
            });
          });
        }
      }
    },
    removeSankeyChartsFilter(index) {
      this.sankeyChartsFilter.splice(index, 1);
    },
    addSankeyChartsFilter() {
      this.sankeyChartsFilter.push("");
    },
  },
};
</script>

<style lang="scss" scoped>
#reportOverview {
  background-color: white;
  padding: 14px;
  border-radius: 10px;
  width: 99%;
  margin: 5px;
  .filerItem {
    background-color: rgb(234 235 185);
    border-radius: 20px;
    text-align: center;
    margin-top: 4px;
  }
  .icon-container {
    display: inline-block;
    position: relative;
  }

  .icon {
    font-size: 24px;
    cursor: pointer;
  }

  .iconcontent {
    display: block;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    width: max-content;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 10;
    transition: opacity 0.3s ease, transform 0.3s ease;
    opacity: 0;
    transform: translateX(-10px);
  }

  .icon:hover + .iconcontent {
    display: block;
    opacity: 1;
    transform: translateX(0);
  }
  .workflowCharts,
  .workflowReports {
    // height: calc(100vh - 235px);
    border: 1px solid #fff;
    padding: 5px;
    border-radius: 10px;
    background: white;
    width: 100%;
    .chart {
      height: 450px !important;
    }

    .border-bottom {
      border-bottom: 1px solid var(--divider-color);
    }
  }
  table {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
    margin-bottom: 15px;

    tr {
      height: 36px;

      th {
        color: var(--secondary);
        text-align: left;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      padding: 8px;
      font-weight: 500;
    }
  }
}

#workflow_overview_dashboard {
  background-color: white;
  padding: 14px;
  border-radius: 10px;
  width: 99%;
  margin: 5px;
  .content {
    padding-top: 8px;
    min-height: calc(100vh - 300px);
  }
  .ellipsis:hover {
    overflow: visible;
    white-space: normal;
    word-break: break-all;
  }

  .backGround {
    border: 2px;
    border-radius: 10px;
    background-color: #87dcf626;
    margin-bottom: 8px;
  }
  .count {
    color: rgb(145, 6, 209);
    font-weight: 800;
    font-size: 22px;
  }
}
</style>
