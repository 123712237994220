<template>
  <AppLayout>
    <ViewWrapper
      title="Dashboard"
      :breadcrumbs="[]"
      tab=""
      :tabs="[]"
      class="q-pa-sm"
      @quickHelp="showQuickHelp"
    >
      <!-- quick help -->
      <template #header-actions>
        <div class="q-mr-xs q-ml-md">
          <ExpandableSearch v-if="WfilterList.length" @search="searchRow" />
        </div>
        <BaseActionButton
          v-if="WfilterList.length"
          v-tooltip.top="'refresh'"
          is-flat
          no-border
          icon="eva-refresh"
          class="q-ml-sm"
          @click="getFilterList"
        />
        <div v-if="workflowList.length > 1" class="item-list">
          <div class="row items-center">
            <div class="label">{{ selectedFilter }}</div>
            <BaseIcon name="eva-chevron-down" inherit-color class="icon" />
          </div>

          <q-menu
            fit
            auto-close
            transition-show="scale"
            transition-hide="scale"
          >
            <!-- <ListItem
              v-for="item in workflowList"
              :key="item.id"
              :is-selected="selectWorkflow === item.value"
              :label="item.label"
              class="text-capitalize"
              @click="selectWorkflow = item.value"
            /> -->
          </q-menu>
        </div>
      </template>
      <QuickHelp v-model="quickHelp" />

      <!-- ... -->

      <!-- child routes -->
      <div v-if="extraContent" id="search">
        <!-- pie chart -->
        <div class="row q-col-gutter-md">
          <div v-if="false" class="col-4">
            <div id="chart-wrapper">
              <div class="header">
                <div class="title">Workflow</div>

                <q-space />
              </div>

              <div class="chart">
                <vue-highcharts
                  ref="refCharts"
                  :highcharts="Highcharts"
                  :options="pieChartOptions"
                ></vue-highcharts>
              </div>
            </div>
          </div>

          <template v-for="(value, key) in workflowCountChart">
            <div :key="key" class="col-4">
              <div class="card chartBox">
                <div class="row text-capitalize items-center header">
                  {{ key }}
                </div>
                <div class="column">
                  <div class="col self-center q-mt-lg q-mb-sm count">
                    {{ value.totalCount }}
                  </div>
                  <div class="col self-center">
                    <BaseIcon name="mdi-triangle" color="positive" />
                    {{
                      Math.round((value.todayCount / value.totalCount) * 100) ||
                      0
                    }}
                    % increase previous day : {{ value.todayCount }}
                  </div>
                </div>
              </div>
            </div>
          </template>

          <div class="col-3">
            <div class="card q-pa-md piechart">
              <div class="row items-center header">Recent Submissions</div>
              <BaseScrollbar height="calc(100vh - 435px)">
                <template v-for="(recent, index) in recentWorkflowListData">
                  <div
                    v-if="
                      recent.duration.split(' ')[0] <= 24 &&
                      (recent.duration.split(' ')[1] == 'hours' ||
                        recent.duration.split(' ')[1] == 'hour' ||
                        recent.duration.split(' ')[1] == 'minutes')
                    "
                    :key="index"
                    class="row items-center q-my-sm"
                  >
                    <BaseIcon
                      v-if="recent.type == 'Inbox'"
                      name="eva-email-outline"
                      color="warning"
                      class="q-mr-sm"
                    />
                    <BaseIcon
                      v-else-if="recent.type == 'Process'"
                      name="eva-loader-outline"
                      color="secondary"
                      class="q-mr-sm"
                    />
                    <BaseIcon
                      v-else
                      name="eva-checkmark-circle-2-outline"
                      color="positive"
                      class="q-mr-sm"
                    />

                    <div class="col">{{ recent.requestNo }}</div>
                    <div class="col-auto row">
                      <div class="q-mr-md">{{ recent.duration }}</div>
                    </div>
                  </div>
                  <!-- <div :key="index" class="row q-my-xs">
                  <q-icon
                    name="mdi-star"
                    color="primary"
                    class="q-mr-sm"
                    size="18px"
                  />
                  <span class="col-auto">{{ recent.requestNo }}</span>
                  <BaseIcon name="mdi-circle-small" color="secondary" />
                  
                  <span class="col-auto">{{ recent.duration }}</span>
                </div> -->
                </template>
              </BaseScrollbar>
            </div>
          </div>

          <div class="col-3">
            <div class="card">
              <div class="row items-center header">Open vs Close</div>

              <apexchart
                class=""
                height="100%"
                type="donut"
                :options="openCloseChartOptions"
                :series="openCloseChartSeries"
              ></apexchart>
            </div>
          </div>

          <div v-if="selectWorkflow === 4 && checkTenant" class="col-3">
            <div class="card piechart">
              <div class="row items-center header">Workflow Count by Month</div>

              <apexchart
                class=""
                height="100%"
                type="donut"
                :options="stockChartOptions"
                :series="stockChartSeries"
              ></apexchart>
            </div>
          </div>

          <div class="col-6">
            <div class="card piechart">
              <div class="row items-center header">Workflow Count by Month</div>

              <apexchart
                type="line"
                height="90%"
                :options="workflowMonthChartOptions"
                :series="workflowMonthSeries"
              ></apexchart>
            </div>
            <div v-if="selectWorkflow === 4 && checkTenant" class="card">
              <div class="row item-center q-body q-mb-sm">
                <div
                  class="col-auto q-mr-xs cursor-pointer header"
                  :class="chartDataDrilldownStage.month ? 'text-secondary' : ''"
                  @click="
                    chartDataDrilldownStage.month = '';
                    chartDataDrilldownStage.week = '';
                    getSignodeChart(4);
                  "
                >
                  Month
                </div>
                <div
                  v-if="chartDataDrilldownStage.month"
                  class="col-auto cursor-pointer header"
                  :class="chartDataDrilldownStage.week ? 'text-secondary' : ''"
                  @click="
                    chartDataDrilldownStage.week = '';
                    getSignodeChart(4, chartDataDrilldownStage.month);
                  "
                >
                  / {{ chartDataDrilldownStage.month }}
                </div>
                <div
                  v-if="chartDataDrilldownStage.week"
                  class="col-auto q-ml-xs header"
                  @click="chartDataDrilldownStage.week = ''"
                >
                  / {{ chartDataDrilldownStage.week }}
                </div>
              </div>
              <vue-highcharts
                ref="refChartSignode"
                :highcharts="Highcharts"
                :options="chartData"
              ></vue-highcharts>
            </div>
          </div>
        </div>

        <!-- ... -->
      </div>
      <!-- ... -->
      <template v-if="loadingBar || WfilterList.length">
        <BaseScrollbar height="calc(100vh - 150px)">
          <DashboardLoadingBar v-if="loadingBar" />
          <template v-else-if="WfilterList.length">
            <div class="container q-pt-sm">
              <div>
                <draggable v-model="WfilterList" class="row" @end="onDragEnd">
                  <div
                    v-for="item in WfilterList"
                    :key="item.id"
                    :class="getItemClass(item)"
                    class="col"
                    style="margin-top: 0px; margin-bottom: 0px"
                  >
                    <template
                      v-if="
                        item.filterFrom === 'repositoryActivityReport' ||
                        item.filterFrom === 'repositoryOcrReport' ||
                        item.filterFrom === 'workflowReport' ||
                        item.filterFrom === 'repositorySignReport'
                      "
                    >
                      <DashboardFilterView
                        :filter-item="item"
                        :listed-data="listedData"
                        @delete="getFilterList"
                      />
                    </template>
                    <template v-if="item.filterFrom === 'workflowInbox'">
                      <WorkflowInbox
                        :filter-item="item"
                        :listed-data="inboxData"
                        @delete="getFilterList"
                      />
                    </template>
                    <template v-if="item.filterFrom === 'workflowOverview'">
                      <WorkflowRequest
                        :filter-item="item"
                        :listed-data="requestData"
                        @delete="getFilterList"
                      />
                    </template>
                    <template v-if="item.filterFrom === 'repositoryOverview'">
                      <DashboaardRepositoryOverview
                        :item="item"
                        :listed-data="DashboaardRepositoryOverviewData"
                        @delete="getFilterList"
                      />
                    </template>
                    <template v-if="item.filterFrom === 'workflowReportChart'">
                      <WorkFlowReportChart
                        :filter-item="item"
                        :listed-data="workFlowReportChart"
                        @delete="getFilterList"
                      />
                    </template>
                  </div>
                </draggable>
              </div>
            </div>
          </template>
        </BaseScrollbar>
      </template>
      <template v-else>
        <StateWrapper
          icon="o_dashboard"
          title="select dashboard options"
          description="Select dashboard options to ensure quick and easy access to your most important items."
          style="margin-top: 20px"
        />
      </template>

      <ViewDetails
        v-if="extraContent"
        v-model="viewDetailsModal"
        :workflow-details-list="workflowDetailsList"
        :selected-workflow="selectedWorkflow"
        @close="viewDetailsModal = false"
      ></ViewDetails>
    </ViewWrapper>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/app/AppLayout.vue";
import ViewWrapper from "@/components/common/view-wrapper/ViewWrapper.vue";
import QuickHelp from "@/components/common/QuickHelp.vue";
import { report, workflow } from "@/api/factory.js";
import VueHighcharts from "vue2-highcharts";
import { mapState } from "vuex";
import Drilldown from "../../../node_modules/highcharts/modules/drilldown.js";
import Highcharts from "highcharts";
import ViewDetails from "@/views/dashboard/components/ViewDetails";
import Exporting from "../../../node_modules/highcharts/modules/exporting.js";
// import ListItem from "@/components/common/ListItem.vue";
import DashboardFilterView from "./components/DashboardFilterView.vue";
import { dashboard } from "@/api/factory.js";
import WorkflowInbox from "./components/WorkFlowInbox.vue";
import WorkflowRequest from "./components/workflowOverviewRequest.vue";
import draggable from "vuedraggable";
import DashboaardRepositoryOverview from "./components/DashboardRepositoryOverview.vue";
import DashboardLoadingBar from "./components/DashboardLoadingBar.vue";
import ExpandableSearch from "@/components/common/ExpandableSearch.vue";
import WorkFlowReportChart from "./components/WorkflowReportChart.vue";
import StateWrapper from "@/components/common/state/StateWrapper.vue";
Drilldown(Highcharts);
Exporting(Highcharts);

export default {
  name: "Dashboard",

  components: {
    AppLayout,
    ViewWrapper,
    QuickHelp,
    VueHighcharts,
    ViewDetails,
    // ListItem,
    DashboardFilterView,
    WorkflowInbox,
    WorkflowRequest,
    draggable,
    DashboaardRepositoryOverview,
    DashboardLoadingBar,
    ExpandableSearch,
    WorkFlowReportChart,
    StateWrapper,
  },

  data() {
    return {
      Highcharts: Highcharts,
      workflowList: [],
      quickHelp: false,
      workflowCount: [],
      workflowTotalCount: 0,
      pieChartOptions: {
        chart: {
          type: "pie",
          backgroundColor: "",
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          style: {
            fontFamily: "Outfit",
          },
        },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.y}</b>",
        },
        title: {
          text: "",
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#4a89dd",
          "#36bd9a",
          "#f7ba43",
          "#e9573e",
          "#977bdd",
          "#d870ad",
          "#8cc051",
        ],

        plotOptions: {
          pie: {
            allowPointSelect: true,
            borderColor: "",
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              distance: -50,
              filter: {
                property: "percentage",
                operator: ">",
                value: 4,
              },
              style: {
                // fontWeight: "normal",
                // fontSize: "0.7em",
                // textOverflow: "clip",
                fontFamily: "Outfit",
                textShadow: "2px 2px 4px #000000;",
              },
              color: "#fff",
              // format: "{point.name}: {point.y}",
              format:
                '<span style="color:{point.color};">{point.name}: {point.y}</span>',
            },
            showInLegend: false,
          },
          series: {
            borderRadius: 3,
            point: {
              events: {
                click: (e) => {
                  e.stopPropagation();
                  if (e.point.name) {
                    // console.log(e.point);
                    this.getSeriesDetails(e.point.options);
                  }
                },
              },
            },
          },
        },
        series: [
          {
            name: "Workflow",
            colorByPoint: true,
            data: [],
          },
        ],
        drilldown: {
          series: [],
        },
      },
      workflowDetailsList: [],
      viewDetailsModal: false,
      selectedWorkflow: {},
      selectWorkflow: "",
      workflowCountChart: {},
      openCloseChartSeries: [],
      openCloseChartOptions: {
        chart: {
          type: "donut",
        },
        title: {
          text: "",
          align: "left",
        },
        labels: ["Open", "Closed"],
        colors: ["#bfa0fe", "#75c1fd"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: "100%",
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        legend: {
          show: false,
        },
      },
      recentWorkflowList: [],
      recentWorkflowListData: [],
      workflowMonthSeries: [],
      workflowMonthChartOptions: {
        chart: {
          height: "100%",
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "",
          align: "left",
        },
        // grid: {
        //   row: {
        //     colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        //     opacity: 0.5,
        //   },
        // },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
      },
      chartData: {
        chart: {
          type: "spline",
          height: "100%",
        },
        title: {
          text: "",
        },
        subtitle: {
          text: "",
        },
        xAxis: {
          type: "category",
        },
        yAxis: {
          title: {
            text: "Variance",
          },
          labels: {
            formatter: function () {
              return this.value;
            },
          },
        },
        tooltip: {
          crosshairs: true,
          shared: true,
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        colors: ["#bfa0fe"],
        plotOptions: {
          series: {
            cursor: "pointer",
            point: {
              events: {
                click: (e) => {
                  e.stopPropagation();
                  if (e.point.name && e.point.y) {
                    if (this.chartDataDrilldownStage.month === "") {
                      this.chartDataDrilldownStage.month = e.point.name;
                      this.getSignodeChart(4, e.point.name);
                    } else if (this.chartDataDrilldownStage.week === "") {
                      this.chartDataDrilldownStage.week = e.point.name;
                      this.getSignodeChart(
                        4,
                        this.chartDataDrilldownStage.month,
                        e.point.name.replace("Week ", "")
                      );
                    }
                  }
                },
              },
            },
          },
          spline: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [
          {
            name: "Variance",
            data: [],
          },
        ],
        drilldown: {},
      },
      chartDataDrilldownStage: { month: "", week: "" },
      stockChartSeries: [],
      stockChartOptions: {
        chart: {
          type: "donut",
        },
        title: {
          text: "Stock In vs Out",
          align: "left",
        },
        labels: ["StockIn", "StockOut"],
        colors: ["#bfa0fe", "#75c1fd"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 800,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        legend: {
          show: false,
        },
      },
      extraContent: false,
      WfilterList: [],
      listedData: [],
      inboxData: [],
      requestData: [],
      DashboaardRepositoryOverviewData: [],
      currentPage: 1,
      itemsPerPage: 0,
      sortBy: { criteria: "dashboardOrder", order: "ASC" },
      success: false,
      loadingBar: false,
      WfilterListData: [],
      workFlowReportChart: [],
    };
  },

  computed: {
    ...mapState(["darkTheme"]),

    selectedFilter() {
      if (this.workflowList.length) {
        if (this.selectWorkflow) {
          return this.workflowList.find(
            (item) => item.value === this.selectWorkflow
          ).label;
        } else return "Select workflow";
      }
      return "Select workflow";
    },

    checkTenant() {
      let origin = location.origin;
      if (
        origin === "http://52.172.32.88" ||
        origin === "https://demo.ezofis.com"
      ) {
        return true;
      }
      return false;
    },
  },

  watch: {
    darkTheme() {
      this.pieChartOptions.chart.backgroundColor = this.darkTheme
        ? "#1d2129"
        : "#ffffff";
      this.pieChartOptions.plotOptions.pie.dataLabels.color = this.darkTheme
        ? "#ffffff"
        : "#1d2129";
      this.pieChartOptions.plotOptions.pie.borderColor = this.darkTheme
        ? "#ffffff"
        : "#1d2129";
    },

    selectWorkflow() {
      let origin = location.origin;
      if (this.selectWorkflow) {
        if (
          this.selectWorkflow === 4 &&
          (origin === "http://52.172.32.88" ||
            origin === "https://demo.ezofis.com")
        ) {
          this.getSignodeChart(this.selectWorkflow);
          this.getSignodeChart_Stock(this.selectWorkflow);
        }
        this.getWorkflowCount(this.selectWorkflow);
        this.getInboxList();
      }
    },
  },
  async created() {
    await this.getFilterList();
  },
  // mounted() {
  //   this.getUserWorkflow();
  // },

  methods: {
    showQuickHelp() {
      this.quickHelp = true;
    },

    async getUserWorkflow(refresh) {
      this.$store.commit("showLoadingBarPage");
      const { error, payload } = await workflow.getUserWorkflow();

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.workflowList = [];
      if (payload.length) {
        // console.log(payload);
        if (!refresh) this.selectWorkflow = payload[0].id;
        else this.getWorkflowCount(this.selectWorkflow);
        this.getInboxList();

        this.workflowCount = [];
        let series = [];
        payload.forEach((item) => {
          this.workflowList.push({
            id: this.$nano.id(),
            label: item.name,
            value: item.id,
            formId: item.wFormId,
          });
          series.push({
            name: item.name,
            y: item.inboxCount + item.processCount + item.completedCount,
            drilldown: item.name,
          });
          this.pieChartOptions.drilldown.series.push({
            name: item.name,
            id: item.name,
            data: [
              {
                name: "Inbox",
                y: item.inboxCount,
                id: item.id,
              },
              {
                name: "Process",
                y: item.processCount,
                id: item.id,
              },
              {
                name: "Completed",
                y: item.completedCount,
                id: item.id,
              },
            ],
          });
        });
        try {
          this.$refs.refCharts.removeSeries();
        } catch (e) {
          console.log(e);
        }
        this.pieChartOptions.series[0].data = series;
        console.log(series);
        this.$refs.refCharts.addSeries(this.pieChartOptions.series[0]);
      }
    },

    async getSeriesDetails(point) {
      this.$store.commit("showLoadingBarPage");
      this.selectedWorkflow = point;
      // console.log(point);
      let functionName = "";
      if (point.name === "Completed")
        functionName = await workflow.getCompletedList(point.id);
      else if (point.name === "Process")
        functionName = await workflow.getSentList(point.id);
      else if (point.name === "Inbox")
        functionName = await workflow.getInboxList(point.id);

      const { error, payload } = functionName;
      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      // console.log(payload);
      this.workflowDetailsList = payload;
      this.viewDetailsModal = true;
    },

    async getWorkflowCount(id) {
      this.$store.commit("showLoadingBarPage");
      const { error, payload } = await report.getWorkflowCount(id);

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.workflowCountChart = {};
      this.workflowCountChart = payload;
      this.openCloseChartSeries = [];
      for (let count in payload) {
        if (count == "inbox") {
          this.openCloseChartSeries.push(
            payload[count].totalCount + payload["process"].totalCount
          );
        }

        if (count == "completed") {
          this.openCloseChartSeries.push(payload[count].totalCount);
        }
      }
      this.workflowTotalCount =
        payload["inbox"].totalCount +
        payload["process"].totalCount +
        payload["completed"].totalCount;
      let workflow = this.workflowList.find(
        (workflow) => workflow.value === id
      );
      this.workflowMonthSeries = [
        {
          name: workflow.label,
          color: "#7e61c8",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      ];
    },

    async getSignodeChart(id, month = "", weekno = "") {
      this.$store.commit("showLoadingBarPage");
      let input = {
        year: new Date().getFullYear(),
        month: month,
        weekno: weekno,
      };
      const { error, payload } = await report.getSignodeChart(id, input);

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        if (payload.Table.length) {
          let yAxis = [];
          if (weekno) {
            payload.Table.forEach((item) => {
              yAxis.push({
                name: item.Day,
                y: Number(item.Variance),
              });
            });
          } else if (month) {
            payload.Table.forEach((item) => {
              yAxis.push({
                name: "Week " + item.Weekno,
                y: Number(item.Variance),
              });
            });
          } else {
            payload.Table.forEach((item) => {
              yAxis.push({
                name: item.Month,
                y: Number(item.Variance),
              });
            });
          }
          this.chartData.series[0].data = yAxis;

          let lineCharts = this.$refs.refChartSignode;
          lineCharts.removeSeries();

          lineCharts.addSeries(this.chartData.series[0]);
        }
      }
    },

    async getInboxList() {
      this.$store.commit("showLoadingBarPage");
      const { error, payload } = await workflow.getInboxList(
        this.selectWorkflow,
        { filterBy: [] }
      );
      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.recentWorkflowList = [];
      payload.data.forEach((row) => {
        row.value.forEach((process) => {
          this.recentWorkflowList.push({
            key: row.key,
            ...process,
            type: "Inbox",
          });
        });
      });
      await this.getSentList();
    },

    async getSentList() {
      this.$store.commit("showLoadingBarPage");
      const { error, payload } = await workflow.getSentList(
        this.selectWorkflow,
        { filterBy: [] }
      );
      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        payload.forEach((row) => {
          this.recentWorkflowList.push({
            key: "",
            ...row,
            type: "Process",
          });
        });
      }

      await this.getCompletedList();
    },

    async getCompletedList() {
      this.$store.commit("showLoadingBarPage");
      const { error, payload } = await workflow.getCompletedList(
        this.selectWorkflow,
        { filterBy: [] }
      );
      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        payload.forEach((row) => {
          this.recentWorkflowList.push({
            key: "",
            ...row,
            type: "Completed",
          });
        });
        this.recentWorkflowListData = [];
        this.recentWorkflowListData = this.recentWorkflowList.map(
          (process) => ({
            id: process.processId,
            key: process.key,
            type: process.type,
            processId: process.processId,
            requestNo: process.requestNo,
            stage: process.stage,
            raisedAt: this.$day.format(process.raisedAt),
            raisedBy: process.raisedBy,
            transaction_createdBy: process.transaction_createdByEmail,
            duration: this.lastActionDuration(process),
            mlPrediction: process.mlPrediction,
          })
        );
      }
    },

    lastActionDuration(request) {
      const date =
        request.transaction_createdAt instanceof Date
          ? request.transaction_createdAt
          : new Date(request.transaction_createdAt);
      const formatter = new Intl.RelativeTimeFormat("en");
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      };

      const secondsElapsed = (date.getTime() - Date.now()) / 1000;
      for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key];
          return formatter.format(Math.round(delta), key);
        }
      }
    },

    async getSignodeChart_Stock(id) {
      this.stockChartSeries = [];

      this.$store.commit("showLoadingBarPage");
      const { error, payload } = await report.getSignodeChart_Stock(id);

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        if (payload.Table) {
          this.stockChartSeries.push(payload.Table[0].StockOut);
          this.stockChartSeries.push(payload.Table[0].StockIn);
        }
      }
    },

    async getFilterList() {
      this.loadingBar = true;
      const { error, payload } = await dashboard.getDashboardData({
        sortBy: this.sortBy,
        groupBy: "",
        filterBy: [
          {
            groupCondition: "",
            filters: [
              {
                criteria: "pinDashboard",
                condition: "IS_EQUALS_TO",
                value: true,
              },
            ],
          },
        ],
        currentPage: this.currentPage,
        itemsPerPage: this.itemsPerPage,
      });
      this.loadingBar = false;
      if (error) {
        this.$alert.error(error);
        return;
      }

      let data = payload.data[0].value;
      console.log(data, "datase");
      this.listedData = data.filter((item) =>
        [
          "repositoryActivityReport",
          "repositoryOcrReport",
          "workflowReport",
          "repositorySignReport",
        ].includes(item.filterFrom)
      );
      this.inboxData = data.filter(
        (item) => item.filterFrom === "workflowInbox"
      );
      this.requestData = data.filter(
        (item) => item.filterFrom === "workflowOverview"
      );
      this.DashboaardRepositoryOverviewData = data.filter(
        (item) => item.filterFrom === "repositoryOverview"
      );
      this.workFlowReportChart = data.filter(
        (item) => item.filterFrom === "workflowReportChart"
      );
      this.WfilterList = data.map((filter) => ({
        id: filter.id,
        label: filter.name,
        value: filter.name,
        expand: false,
        description: filter.description,
        filterFrom: filter.filterFrom,
        rId: filter.rId,
        filter: filter.filterJson,
        name: filter.name,
        filterJson: filter.filterJson,
        createdAt: filter.createdAt,
        modifiedAt: filter.modifiedAt,
        createdBy: filter.createdBy,
        modifiedBy: filter.modifiedBy,
        isDeleted: false,
        dashboardOrder: filter.dashboardOrder,
      }));
      this.WfilterListData = this.WfilterList;
      console.log(this.WfilterList, "listed");
    },

    getItemClass(item) {
      let parse = JSON.parse(item.filter);
      return item.filterFrom === "repositoryActivityReport" ||
        item.filterFrom === "repositoryOcrReport" ||
        item.filterFrom === "workflowReport" ||
        item.filterFrom === "repositorySignReport" ||
        item.filterFrom === "repositoryOverview" ||
        (item.filterFrom === "workflowOverview" && parse[0].type === "sankey")
        ? "col-12"
        : "col-4";
    },
    async onDragEnd() {
      if (!Array.isArray(this.WfilterList)) {
        console.error("WfilterList is not an array:", this.WfilterList);
        return;
      }
      let maxOrderValue = -1;
      this.WfilterList.forEach((item) => {
        if (item.dashboardOrder !== undefined) {
          maxOrderValue = Math.max(maxOrderValue, item.dashboardOrder);
        }
      });
      this.WfilterList.forEach((item, index) => {
        item.dashboardOrder =
          item.dashboardOrder !== undefined ? index : maxOrderValue + 1;
      });
      let input = [];
      for (const item of this.WfilterList) {
        let order = {
          id: item.id,
          dashboardOrder: item.dashboardOrder,
        };
        input.push(order);
        console.log(order);
      }
      console.log(input, "input");
      const { error } = await dashboard.dashboardOrder(input);
      if (error) {
        this.$alert.error(error);
        return;
      }
    },

    searchRow(search) {
      if (search) {
        const searchLower = search.toLowerCase();

        this.WfilterList = this.WfilterListData.filter(
          (item) =>
            (item.name && item.name.toLowerCase().includes(searchLower)) ||
            (item.description &&
              item.description.toLowerCase().includes(searchLower))
        );
      } else {
        this.WfilterList = this.WfilterListData;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#search {
  padding-top: 14px;
}

#chart-wrapper {
  background-color: var(--component-bg-color);
  border-radius: 10px;
  height: 100%;

  .header {
    display: flex;
    align-items: center;
    padding: 16px 0px;
    margin: 0px 16px;
    border-bottom: 1px solid var(--divider-color);
    @extend .title-2;
    text-transform: capitalize;

    .title {
      font-weight: 600;
      @extend .title-2;
      text-transform: capitalize;
    }
  }

  // .chart {
  //   padding: 16px;
  // }
}

.card {
  // height: calc(100vh - 235px);
  height: 300px;
  background-color: var(--component-bg-color);
  border: 1px solid var(--component-bg-color);
  padding: 10px;
  border-radius: 10px;
  // background: white;

  &.chartBox {
    height: 130px;
  }

  .header {
    text-anchor: start;
    font-size: 15px;
    font-weight: 700;
    fill: rgb(55, 61, 63);
  }

  .label {
    color: #a391cf !important;
  }

  .count {
    color: #8162cc;
    font-weight: 800;
    font-size: 24px;
  }
}

.item-list {
  padding: 4px 4px 4px 8px;
  margin: 0px 8px;
  border: 1px solid var(--divider-color);
  border-radius: 4px;
  text-transform: capitalize;
  font-weight: 500;
  cursor: pointer;

  .label {
    flex: 1;
    color: var(--secondary);
    margin-right: 8px;
  }

  .icon {
    color: var(--icon-color-inverted);
  }
}
</style>
