<template>
  <Sheet :value="value" width="720px" @input="closeSheet">
    <template #title>
      <slot name="title">{{ selectedWorkflow.name }} List</slot>
    </template>

    <!-- form -->
    <template #default>
      <div id="table-field" class="q-pa-md">
        <BaseScrollbar class="q-pb-sm">
          <table>
            <thead>
              <tr>
                <th v-for="column in columns" :key="column.name" class="large">
                  {{ column.label }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="row in workflowDetailsList" :key="row">
                <td v-for="column in columns" :key="column.name">
                  <template
                    v-if="
                      column.name == 'raisedAt' ||
                      column.name == 'transaction_createdAt'
                    "
                  >
                    {{ $day.format(row[column.name]) }}
                  </template>
                  <template v-else>
                    {{ row[column.name] }}
                  </template>
                </td>
                <!-- <td>{{ row }}</td> -->
              </tr>
            </tbody>
          </table>
        </BaseScrollbar>
      </div>
    </template>

    <!-- ... -->
  </Sheet>
</template>

<script>
import Sheet from "@/components/common/popup/Sheet.vue";
import { startCase, lowerCase } from "lodash-es";

export default {
  name: "ViewDetails",

  components: { Sheet },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    selectedWorkflow: {
      type: Object,
      required: true,
    },

    workflowDetailsList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      columns: [],
    };
  },

  computed: {},

  watch: {
    workflowDetailsList: {
      deep: true,
      handler() {
        this.columns = [];
        for (const key in this.workflowDetailsList[0]) {
          if (
            key !== "processId" &&
            key !== "transactionId" &&
            key !== "activityId" &&
            key !== "ruleId" &&
            key !== "FormData" &&
            key !== "flowStatus"
          )
            this.columns.push({
              name: key,
              label: startCase(lowerCase(key)),
            });
        }
      },
    },
  },

  methods: {
    closeSheet() {
      this.$emit("close", false);
    },

    isValidDate(value) {
      //   console.log(value);
      var dateWrapper = new Date(value);
      console.log(dateWrapper);

      return !isNaN(dateWrapper.getDate());
    },
  },
};
</script>

<style lang="scss" scoped>
#table-field {
  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;

    tr {
      height: 48px;
    }

    th {
      padding: 4px 8px;
      text-align: left;
      font-weight: 500;
      text-transform: capitalize;

      &.small {
        width: 70px;
      }

      &.medium {
        width: 140px;
      }

      &.large {
        width: 210px;
      }

      &.actions {
        width: 48px;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
    }

    td {
      padding: 4px;
      vertical-align: top;
    }

    td .label {
      display: flex;
      align-items: center;
      height: 48px;
      padding-left: 4px;
      color: var(--icon-color);
    }
  }
}
</style>
